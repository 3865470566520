<template>
  <div class="task-page">
    <div class="content-left">
      <treedata @projectId-child="projectIdClick" @projectId-key="projectKey"></treedata>
    </div>
    <div class="content-right">
      <div class="content-header">
        <p>
          <span></span>
          <span>课题概况</span>
        </p>
      </div>
      <div style="margin-left: 20px">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projecttype.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass  == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass  == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass  == 3">子课题</span>
              <span class="span-left">类型</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.TypeName==null?"":list.TypeName}}</p>
                </div>
                <span class="span-right">{{list.TypeName==null?"":list.TypeName}}</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/subjectdirection.png" alt="">
              </div>
              <span class="span-left">主题方向</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.TopicDirection}}</p>
                </div>
                <span class="span-right">{{list.TopicDirection}}</span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectname.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass == 3">子课题</span>
              <span class="span-left">名称</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.TopicName}}</p>
                </div>
                <span class="span-right">{{list.TopicName}}</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectno.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass == 3">子课题</span>
              <span class="span-left">编号</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.TopicCode}}</p>
                </div>
                <span class="span-right">{{list.TopicCode}}</span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectname.png" alt="">
              </div>
              <span class="span-left" >预算总额</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.Funds}}</p>
                </div>
                <span class="span-right" v-if="list.Funds">{{list.Funds}}万元</span>
                <span class="span-right" v-else>0</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectno.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass == 3">子课题</span>
              <span class="span-left">序号</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.SerialNumber}}</p>
                </div>
                <span class="span-right">{{list.SerialNumber}}</span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectleads.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass == 3">子课题</span>
              <span class="span-left">牵头单位(甲方)</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-tooltip placement="top">
                <div slot="content">
                  <p class="tooltip-content">{{list.HospitalName}}</p>
                </div>
                <span class="span-right">{{list.HospitalName}}</span>
              </el-tooltip>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectleader.png" alt="">
              </div>
              <span class="span-left" v-if="list.TopicClass == 1">项目</span>
              <span class="span-left" v-if="list.TopicClass == 2">课题</span>
              <span class="span-left" v-if="list.TopicClass == 3">子课题</span>
              <span class="span-left">负责人</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right">{{list.Principal}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/time.png" alt="">
              </div>
              <span class="span-left">起止年限</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right" v-if="list.StartDate">{{list.StartDate.substring(0,10)}}-{{list.EndDate.substring(0,10)}}</span>
<!--              </el-tooltip>-->
<!--              <span v-if="list1.length!=0" class="span-right">{{list1.StartDate}}-{{list1.EndDate}}</span>-->
<!--              <span v-if="list2.length!=0" class="span-right">{{list1.StartDate}}-{{list1.EndDate}}</span>-->

            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectleader.png" alt="">
              </div>
              <span class="span-left">管理员</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right">{{list.UserName}}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="content-textarea">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/scientificcontent.png" alt="">
              </div>
              <span class="span-left">科研内容</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <div class="task-img">
                <img src="../../../assets/image/taskmanagement/projectleader.png" alt="">
              </div>
              <span class="span-right">审核流程</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <span class="span-right" v-if="list.IsCheck">有</span>
              <span class="span-right" v-else>无</span>
            </div>
          </el-col>
        </el-row>
        <div>
          <el-input
            readonly
            type="textarea"
            :rows="6"
            v-model="list.Remarks">
          </el-input>
        </div>
        <div class="grid-content">
          <div class="task-img">
            <img src="../../../assets/image/taskmanagement/assessmentindex.png" alt="">
          </div>
          <span class="span-left">考核指标</span>
        </div>
        <div>
          <el-input
            readonly
            type="textarea"
            :rows="6"
            v-model="list.Assess">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import research from '../../../api/research'
import treedata from "@/components/treedata";
import {mapState} from "vuex";
export default {
  data(){
    return{
      dialogRemarks: false,
      dialogAssess: false,
      list:[],
      list1:[],
      list2:[],
      childId: null
    }
  },
  computed: mapState(["projectId","Key"]),
  components:{
    treedata
  },
  methods:{
    projectKey(data) { // key 判断子课题
      this.$store.commit('nodeKey',data)
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      console.log(this.projectId,'课题id是多少')
      this.abc()
    },
    async abc(){
      let parame=this.projectId==""?sessionStorage.getItem("projectId"):this.projectId
      await research.topic(parame).then(res=>{
        console.log(res,"1111111");
        if(res.data.Status==1){
          this.list=res.data.ExtraData
        }
      })
    }
  },
  mounted() {
    this.abc()
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
  .task-page {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    .content-left {
      width: 260px;
      height: 94%;
      margin-top: 2%;
      border-radius: 10px;
      //box-sizing: border-box;
      background: white;
    }
    .content-right {
      //width: 1340px;
      width: 83%;
      height: 94%;
      margin-top: 2%;
      margin-right: 1%;
      background: #FFFFFF;
      padding: 1% 1% 0 1%;
      border-radius: 10px;
      box-sizing: border-box;
      margin-left: 20px;
      .content-header {
        margin-top: 19px;
        margin-bottom: 38px;
        p {
          span:nth-child(1) {
            display: inline-block;
            width: 3px;
            height: 16px;
            background: #3388ff;
            margin-right: 10px;
            vertical-align: middle;
          }
          span:nth-child(2) {
            display: inline-block;
            color: #333333;
            @include add-size1($font_size_20);
            vertical-align: middle;
          }
        }
      }
    }
    .grid-content {
      display: flex;
      height: 36px;
      margin-bottom: 15px;
      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;
      }
      span {
        //width: 30px;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .span-left {
        color: #666;
        @include add-size4($font_size_18);
      }
      .span-right {
        color: #69696A;
        @include add-size4($font_size_18);
      }
    }
    .content-textarea {
      margin-left: 20px;
      /deep/.el-textarea {
        width: 95%;
        margin-left: 31px;
        margin-bottom: 32px;
        border: 1px solid #CBCBDE;
        opacity: 1;
        border-radius: 6px;
        @include add-size($font_size_16);
      }
    }
  }
.tooltip-content {
  @include add-size($font_size_16);
}
</style>
